import axios from 'axios';
import { deleteLoginJWT, loadLoginJWT } from './Storage';

// url na server 
const BASE_URL = window.location.hostname === "localhost" ? 'http://localhost:8080/' : 'https://api.kotelny.rozekja.fun/'

// Endpointy
export const ENDPOINTS = {
    AUTHENTIZATION : "auth/",
    PERMISSION : "api/permissions/",
    ROLE : "api/roles/",
    USER : "api/users/",
    BOILER_ROOM : "api/boiler_rooms/",
    BOILER_ROOM_ADD_RECORD : "api/boiler_rooms/add_records/",
    BOILER_ROOM_DELETE_RECORD : "api/boiler_rooms/delete_record/",
    BOILER_ROOM_GENERATE_RECORDS : "api/boiler_rooms/generate_records/",
    BOILER_ROOM_GET_RECORDS : "api/boiler_rooms/get_records_by_boiler_date/"
}

export const load = async (endpoint, ids = [], skipAccesToken = false) => {
    let data = []

    const config = await getRequestConfig(skipAccesToken)

    if (ids.length > 0) {
        for (let i = 0; i < ids.length; i++) {
            let temp
            if (Array.isArray(ids[i])) {
                let urlSuffix = ""
                for (let j = 0; j < ids[i].length; j++) {
                    urlSuffix += `${ids[i][j]}/`
                }

                temp = (await axios.get(BASE_URL + endpoint + urlSuffix, config)).data
            } else {
                temp = (await axios.get(BASE_URL + endpoint + ids[i], config)).data
            } 

            if (!Array.isArray(temp)) {
                data.push(temp)
            } else {
                data = [... data, ...temp]
            }
        }
    } else {
        data = (await axios.get(BASE_URL + endpoint, config)).data
    }

    return data;
}

export const store = async (endpoint, data) => {
    let reseavedData = []

    const config = await getRequestConfig()

    if (config.headers !== undefined)
        config.headers["Content-Type"] = "application/json"


    for (let i = 0; i < data.length; i++) {

        const url = BASE_URL + endpoint

        let temp
        if (data[i].id === null) 
            temp = (await (axios.post(url, data[i], config))).data
         else 
            temp = (await (axios.put(url + data[i].id, data[i], config))).data

        reseavedData.push(temp)
    }

    return reseavedData
}

export const storeRecords = async (id, data) => {
    const config = await getRequestConfig()

    if (config.headers !== undefined)
        config.headers["Content-Type"] = "application/json"


    const url = BASE_URL + ENDPOINTS.BOILER_ROOM_ADD_RECORD + id

    return (await (axios.post(url, data, config))).status
}

export const remove = async (endpoint, data) => {
    const config = await getRequestConfig()


    for (let i = 0; i < data.length; i++) {
        await axios.delete(BASE_URL + endpoint + `${data[i].id}`, config)
    }

    return data
}

export const login = async (user) => {
    const headers = {
        "Content-Type": "application/json"
    }
    const config = {
        headers: headers
    }
    
    const res = (await axios.post(BASE_URL + ENDPOINTS.AUTHENTIZATION + "login", user, config))

    if (res.status === 200)
        return res.data
    return null
}

export const logout = async () => {
    
    deleteLoginJWT()
}

// TODO přidat reset hesla a změnu


// funkce získá přístupový token
async function getAccessToken() {

    const loginToken = loadLoginJWT()

    const headers = {
        "Content-Type": "application/json"
    }

    const config = {
        headers: headers
    }

    try {

        let accessToken = (await axios.post(BASE_URL + ENDPOINTS.AUTHENTIZATION + "token", loginToken, config)).data

        return accessToken
    } catch (error) {

        console.log(error);
       // logout(loginToken)
        return ""
    }

}

// metoda vytvoří config objekt pro request na api
async function getRequestConfig(skipAccesToken) {
    
    let accessToken = ""

    if (!skipAccesToken)
        accessToken = await getAccessToken()
    
    let headers = {
        "authorization": accessToken
    }

    let config = {
        headers: headers
    }

    return config
}