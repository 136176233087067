import { useNavigate, useParams } from 'react-router-dom'
import './GeneratedPreview.css'
import { useEffect, useState } from 'react'
import { countConsumption } from '../global/Constants'
import { ENDPOINTS, load, store, storeRecords } from '../global/API'

const GeneratedPreview = () => {

    const navigate = useNavigate()
    const { id } = useParams()

    const [selectedRecortd, setSelectedRecord] = useState({})
    const [boilerroom, setBoilerroom] = useState({})
    const [min, setMin] = useState(2)
    const [max, setMax] = useState(2)

    const [generatedRecords, setGeneratedRecords] = useState([])

    const [consumption, setConsumption] = useState(0)

    useEffect(() => {
        const record = sessionStorage.getItem("selectedRecord")
        if (record === null || id === undefined) 
            navigate("/")

        load(ENDPOINTS.BOILER_ROOM, [id])
        .then(data => { 
            setBoilerroom({...data[0]})
        })
        .catch(err => console.log(err))
        setSelectedRecord(JSON.parse(record))
    }, [])

    useEffect(() => {
        if (selectedRecortd.stateOfGasometer !== undefined)
            store(ENDPOINTS.BOILER_ROOM_GENERATE_RECORDS, [{
                id: id,
                record: selectedRecortd,
                min: min,
                max: max
            }])
            .then(data => setGeneratedRecords([...data[0]]))
            .catch(err => console.log(err))
    }, [selectedRecortd])

    useEffect(() => {

        const lastStatementOfGasometer = generatedRecords[generatedRecords.length -1]?.stateOfGasometer

        setConsumption(lastStatementOfGasometer - boilerroom.lastRecord?.stateOfGasometer)
    }, [generatedRecords])


    return (
        <div className='generated-preview'>
            <h2>Náhled na vygenerovaná data</h2>
            <div className='generated-preview-minmax'>
                <div>
                    <label>Záporná odchylka</label>
                    <input type='number'  name='min' value={min} onChange={(e) => {
                        const {value} = e.target

                        setMin(parseFloat(value))
                    }} />
                </div>
                <div>
                    <label>Kladná odchylka</label>
                    <input type='number'  name='max' value={max} onChange={(e) => {
                        const {value} = e.target

                        setMax(parseFloat(value))
                    }} />
                </div>
            </div>

            <h3>Generováno na spotřebu: { consumption }</h3>
            <h3>Poslední stav plynoměru: { boilerroom.lastRecord?.stateOfGasometer }</h3>

            <div className='generated-preview-records'>
                {
                    generatedRecords.map((r,i,arr) => {
                        return <div key={i}>
                            <h3>{r.recorded.join(".")}</h3>
                            <p><b>Dení spotřeba:</b> { countConsumption(r.stateOfGasometer, i === 0 ? boilerroom.lastRecord?.stateOfGasometer : arr[i-1].stateOfGasometer) }</p>
                            <label>Stav plymoněru: </label>
                            <input type='number' 
                                value={r.stateOfGasometer}
                                readOnly={i === arr.length - 1}
                                onChange={(e) => {
                                    const { value } = e.target

                                    r.stateOfGasometer = parseFloat(value)
                                    generatedRecords[i] = r
                                    setGeneratedRecords([...generatedRecords])
                                }} />
                        </div>
                    })
                }
            </div>
            <div className='generated-preview-btns'>
                <button onClick={() => navigate("/")}>Zahodit</button>
                <button onClick={() => setSelectedRecord({...selectedRecortd})}>Vygenerovat znovu</button>
                <button onClick={() => {
                    storeRecords(id, generatedRecords)
                    .then(status => navigate("/"))
                    .catch(err => console.log(err))
                }}>Potvrdit</button>
            </div>
        </div>
    )
}

export default GeneratedPreview